/* unplugin-vue-components disabled */import __unplugin_components_6 from '/workspace/src/components/EmptyData.vue';
import __unplugin_components_5 from '/workspace/src/components/FeatherIcon.vue';
import __unplugin_components_4 from '/workspace/src/components/ButtonGroup.vue';
import __unplugin_components_3 from '/workspace/src/components/TableSimple.vue';
import __unplugin_components_2 from '/workspace/src/components/ButtonCustom.vue';
import __unplugin_components_1 from '/workspace/src/components/AddSidebarLevel.vue';
import __unplugin_components_0 from '/workspace/src/components/PageSection.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,{attrs:{"loading":_vm.loading}},[_c(__unplugin_components_1,{attrs:{"value":_vm.sidebarActive,"default-value":_vm.sidebarLevel,"series":_vm.series,"level-order":_vm.levelOrder},on:{"update:value":function($event){_vm.sidebarActive=$event}}}),_c(__unplugin_components_2,{attrs:{"title":_vm.$t('#Level #Button Add Level'),"icon":"PlusIcon","variant":"primary"},on:{"click":function($event){return _vm.onCreateLevel()}}}),_c(__unplugin_components_3,{attrs:{"headers":_vm.headers,"items":_vm.levels,"draggable":"","on-drag-change":_vm.onDragLevelChange},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(" "+_vm._s(item.languages[_vm.$i18n.locale].title)+" ")])]}},{key:"cell(slug)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.slug)+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c(__unplugin_components_4,[_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"dropdown-icon-wrapper",attrs:{"variant":"outline-secondary","disabled":item.status!=='active'},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b',[_c(__unplugin_components_5,{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.onDeleteLevel(item)}}},[_vm._v(_vm._s(_vm.$t("#Button Delete"))+" ")]),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.onEditLevel(item)}}},[_vm._v(_vm._s(_vm.$t("#Button Edit"))+" ")])],1),_c('router-link',{staticClass:"link",attrs:{"to":{ name:'lab-open-level', params: { seriesId: _vm.seriesId, levelId: item.id } }}},[_vm._v(" "+_vm._s(_vm.$t('#Button Open'))+" ")])],1)]}},{key:"empty",fn:function(){return [_c(__unplugin_components_6,{attrs:{"title":_vm.$t('#EmptyData #No Level Title')}},[_c('span',[_vm._v(_vm._s(_vm.$t('#EmptyData #No Level Message')))])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }