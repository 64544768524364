import _objectSpread from "/workspace/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'Level',
  props: {
    seriesId: {
      type: undefined,
      required: true
    }
  },
  data: function data() {
    return {
      loading: true,
      sidebarActive: false,
      sidebarLevel: null,
      levelsTemp: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    pagination: 'level/pagination',
    levels: 'level/levels',
    series: 'series/selectedSeries'
  })), {}, {
    levelOrder: function levelOrder() {
      if (this.levels) {
        return this.levels.length;
      }

      return 0;
    },
    headers: function headers() {
      return [{
        name: 'title',
        title: this.$t('#Level #TableHeader Title')
      }, {
        name: 'slug',
        title: this.$t('#Level #TableHeader Slug')
      }, {
        name: 'actions',
        title: '',
        style: {
          width: '10%'
        }
      }];
    }
  }),
  beforeMount: function beforeMount() {
    var _this = this;

    this.$store.dispatch('series/findById', this.$route.params.seriesId).then(function () {
      _this.$store.dispatch('level/findBySeriesId', {
        seriesId: _this.series.id,
        pagination: _this.pagination
      }).then(function () {
        _this.loading = false;
      });
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions({
    softDelete: 'level/softDelete',
    update: 'level/update'
  })), {}, {
    onCreateLevel: function onCreateLevel() {
      this.sidebarActive = !this.sidebarActive;
    },
    onDeleteLevel: function onDeleteLevel(level) {
      var _this2 = this;

      this.$swal({
        icon: 'warning',
        title: this.$t('#SweetAlertTitle Delete Level'),
        html: this.$t('#SweetAlertDescription Delete Level'),
        showCancelButton: true,
        cancelButtonText: this.$t('#SweetAlertButton Cancel'),
        confirmButtonText: this.$t('#SweetAlertButton Confirm'),
        customClass: {
          confirmButton: 'btn btn-secondary color-secondary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(function (result) {
        if (result.isConfirmed) {
          _this2.softDelete(level.id).then(function () {
            _this2.$swal({
              icon: 'success',
              title: _this2.$t('#SweetAlertTitle Success'),
              html: _this2.$t('#SweetAlertDescription Success'),
              showConfirmButton: false,
              timer: 1500
            });

            _this2.onDragLevelChange();
          }).catch(function () {
            _this2.$swal({
              icon: 'error',
              title: _this2.$t('#SweetAlertTitle Error'),
              html: _this2.$t('#SweetAlertDescription Error'),
              confirmButtonText: _this2.$t('#SweetAlertButton Confirm'),
              showCancelButton: false,
              customClass: {
                confirmButton: 'btn btn-outline-danger background-primary'
              }
            });
          });
        }
      });
    },
    onEditLevel: function onEditLevel(level) {
      this.sidebarLevel = _.cloneDeep(level);
      this.sidebarActive = !this.sidebarActive;
    },
    onDragLevelChange: function onDragLevelChange() {
      var _this3 = this;

      var levelsOrdered = this.levels.map(function (item, index) {
        var level = item;
        level.order = index;
        return level;
      });
      levelsOrdered.forEach(function (level) {
        _this3.update({
          id: level.id,
          level: level
        });
      });
    }
  })
};