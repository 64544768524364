//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { BAvatar } from 'bootstrap-vue';
export default {
  components: {
    BAvatar: BAvatar
  },
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    hideClose: {
      type: Boolean,
      default: false
    }
  }
};